import React from "react";
import StartPage from "./assets/components/StartPage";
import QuizPage from "./assets/components/QuizPage";
import ScoreBoard from "./assets/components/ScoreBoard";

function App() {
  const [quizzical , setQuizzical] = React.useState(false)
  const [quizQues, setQuizQues] = React.useState([])
  const [refreshQuestions, setRefreshQuestions] = React.useState(true)
  
  const [score, setScore] = React.useState()
  const [hiScore, setHiScore] = React.useState(localStorage.getItem('hiScore')?localStorage.getItem('hiScore'):0)

  const [questNum, setQuestNum] = React.useState()

  React.useEffect(() => {
    async function fetchData(){
      const res = await fetch("https://opentdb.com/api.php?amount=10&type=multiple")
      const data = await res.json()
      const newQuestArr = data.results.map(question => {
        const newQuest = {
          statement: question.question,
          options: [...question.incorrect_answers , question.correct_answer]
                      .sort(() => Math.random() - 0.5),
          correct: question.correct_answer
        }
        newQuest.options = newQuest.options.map((option,index) => ({
          optionVal:option,
          id:index,
          state:"radio"
        }
        ))
        return newQuest
      })
      setQuizQues(newQuestArr)
      setScore(0)
      setQuestNum(0)
    }
    fetchData()
  },[refreshQuestions])
  
  if(localStorage.getItem("hiScore") < score){
    localStorage.setItem("hiScore",score)
    setHiScore(localStorage.getItem("hiScore"))
  }
  console.log(quizQues)
  

  return (
    <div className="app">
      {!quizzical ?
      <StartPage someFunc={() => {
        setQuizzical(true)
        }}/>
         : questNum < 10 ?
         <QuizPage 
         data={quizQues} 
          score={score}
          hiScore={hiScore}
          questNum={questNum} 
          setScore={(value) => setScore(value)}
          setQuestNum={(value) => setQuestNum(value)}
           />
         : <ScoreBoard 
          score={score}
          setRefreshQuestions={(value) => setRefreshQuestions(value)}
         /> 
       }
    </div>
  );
}

export default App;
