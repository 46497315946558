import {nanoid} from "nanoid"
import React from "react"

function QuizPage(props){    
    
    const [optArr , setOptsArr] = React.useState(props.data[props.questNum].options)

    /* Used for getting an option array */

    React.useEffect(() =>{
        console.log("First call on mount..");
        setOptsArr(props.data[props.questNum].options)
        return () => console.log("Cleanup..");
    },[props.questNum])

    /* ---------------------------------- */

    
    /* function to change Question */
    const changeQues = () => {
        if(props.questNum +1 <= props.data.length){
            props.setQuestNum(prevNum => prevNum+1)
        }
        setIsDisabled(false)

    }
    
    const submit = () =>{
        props.setQuestNum(prevNum => prevNum+1)
    }

    /* ---------------------------- */
    const handleAnswer = (event) => {
        const {id} = event.target

        setOptsArr(prevItem => prevItem.map(option => {
            setIsDisabled(true)
            if(option.id == id){
                if(option.optionVal === props.data[props.questNum].correct){
                    props.setScore(prevScore => prevScore+1 )
                    return {
                        ...option,
                        state:"radio green"
                    }
                }else{
                    return {
                        ...option,
                        state:"radio red"
                    }
                }
            }else{
                if(option.optionVal === props.data[props.questNum].correct){
                    return {
                        ...option,
                        state:"radio green"
                    }
                }else{
                    return option
                }
            }
        }))

        

    }
    const [isDisabled , setIsDisabled] = React.useState(false)
    /*Generating options*/
    const optElems = optArr.map(item => {
        return (
            <button 
                className={item.state}
                key={nanoid()} 
                value={item.optionVal}
                id={item.id}
                dangerouslySetInnerHTML={{__html: item.optionVal}}
                onClick={handleAnswer}
                disabled={isDisabled}
            ></button>
        )
    })

    /* ---------------------------- */

    return (
        <main className="quizPage flex--col">
            <span className="score">SCORE : {props.score}</span>
            <span className="hiScore">HI SCORE : {props.hiScore}</span>
            <h1 style={{marginTop:"auto", marginBottom:"1rem"}}>Question {props.questNum+1}/10</h1>
            <p dangerouslySetInnerHTML={{__html: props.data[props.questNum].statement}}></p>
                {optElems}
            
            <button 
                className="start-quiz-btn" 
                onClick={() => props.questNum<9? changeQues() : submit()}>
                    <span>{props.questNum < 9? "Next Question":"Submit"}</span>
            </button>
        </main>
    )
}
export default QuizPage;