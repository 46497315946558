function StartPage(props){
    return(
        <main className="startPage flex--col">
            <div id="loader" className="loader">
                <span>
                    <ion-icon name="cog-outline"></ion-icon>
                </span>
            </div>
            <h1>Quizzical</h1>
            <h4>Test yourself and grow more</h4>
            <button className="start-quiz-btn" onClick={props.someFunc}><span>START QUIZ</span></button>
        </main>
    )
}

export default StartPage;