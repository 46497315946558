import Confetti from "react-confetti"
import useWindowSize from "react-use/lib/useWindowSize"

export default function ScoreBoard(props){
  const { width , height} = useWindowSize

    return(
        <>
            <Confetti
                width={width}
                height={height}
            />
        <main className="flex--col">
                <h1 style={{marginTop:"auto"}}>{props.score}</h1>
                <p style={{fontWeight: 600,fontSize:"1.2rem"}}>Your score is:<br/><br/>
                <br/>
                
                {props.score < 5 ?
                 "Congrats!! for reaching till end.. Better Luck next time"
                : props.score <8?"Congrats!! your knowledge is above average":"Congrats!! You have good General Knowledge"
                }

            </p>
            <button className="start-quiz-btn" onClick={() => props.setRefreshQuestions(prevState => !prevState)}><span>Start Again?</span></button>
        </main>
    </>
    )
}

